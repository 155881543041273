<template>
    <section class="invoice-preview-wrapper">
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, indexVal) in values" :key="indexVal">
              {{ value }}
            </li>
          </ul>
        </div>
      </b-alert>
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <!-- <p class="card-text mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </p>
                  <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                  <p class="card-text mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </p> -->
                </div>
                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Invoice

                    <span class="invoice-number">#{{ subscriptionData.code }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Date created:</p>
                    <p class="invoice-date" v-for="(transaction, index) in  subscriptionData.transactions" :key="index" >
                      {{ transaction.created_at  | formatDate }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing" />
  
            <!-- Invoice Client & Payment Details -->
            <b-card-body v-if="subscriptionData.user" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">Invoice To:</h6>
                  <h6 class="mb-25">
                    {{ subscriptionData.user.firstname }} {{ subscriptionData.user.lastname }}
                  </h6>
  
                  <p v-if="subscriptionData.address" class="card-text mb-25">
                    {{ subscriptionData.address }},
                  </p>
                  <p class="card-text mb-25">
                    {{ subscriptionData.user.phone }}
                  </p>
                  <p class="card-text mb-0">
                    {{ subscriptionData.user.email }}
                  </p>
                </b-col>
  
                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">Payment Details:</h6>
                    <table>
                      <tbody  v-for="(transaction, index) in  subscriptionData.transactions" :key="index">
                        <tr>
                          <td class="pr-1">Total Due:</td>
                          <td>
                            <span class="font-weight-bold">{{
                              transaction.amount
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Transaction Fees:</td>
                          <td>
                            {{ transaction.transaction_fees }}
                          </td>
                        </tr>
                         <tr>
                          <td class="pr-1">Commission:</td>
                          <td>{{ transaction.payment_commission }}</td>
                        </tr>
                        <tr>
                        <tr>
                          <td class="pr-1">Payment Method:</td>
                          <td>{{ transaction.payment_method }}</td>
                        </tr>
                       
                          <td class="pr-1">Gateway Type:</td>
                          <td>
                            {{ transaction.payment_gateway_type }}
                          </td>
                        </tr>
  
                        <tr>
                          <td class="pr-1">Transaction Ref:</td>
                          <td>
                            {{ transaction.transaction_ref }}
                          </td>
                        </tr>
                       
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
  
            <!-- Invoice package: Table -->
    
         <b-col md="12" >
                    <p class="text-secondary h4 mt-1">package</p>
                    <table class="table table-striped table-responsive" >
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">code</th>
                          <th scope="col">Ads Limit</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <td dir="rtl"> {{ JSON.parse(JSON.parse(subscriptionData.package_snapshot).name).name_en }}</td>
                          <td style="text-align: center"> {{ JSON.parse(subscriptionData.package_snapshot).code }}</td>
                          <td>
                            <b-badge>
                              {{ JSON.parse(subscriptionData.package_snapshot).ads_limit }}
                            </b-badge>
                          </td>
                          <td>
                              
                              {{ JSON.parse(subscriptionData.package_snapshot).price }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </b-col>

  
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                </b-col>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                   
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        {{ subscriptionData.total_paid }} EGP
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing" />
  
            <!-- Note -->
          </b-card>
        </b-col>
        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              block
              @click="printInvoice"
            >
              Print
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </template>
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { heightTransition } from '@core/mixins/ui/transition'
  export default {
    components: {
      Logo,
    },
    mixins: [heightTransition],
    data() {
      return {
        id: 0,
        showDismissibleAlert: false,
        errors_back: [],
        subscriptionData: [],
        fields: [
          {
            key: 'package_snapshot',
            label: 'name',
          },
          {
            key: 'original_price',
            label: 'original price',
          },
          {
            key: 'vendor_price',
            label: 'vendor price',
          },
          {
            key: 'margin',
            label: 'margin',
          },
          {
            key: 'offer_amount',
            label: 'discount',
          },
          {
            key: 'price',
            label: 'price',
          },
  
          {
            key: 'quantity',
            label: 'quantity',
          },
          {
            key: 'total',
            label: 'total',
          },
        ],
      }
    },
    setup() {
      const printInvoice = () => {
        window.print()
      }
      return {
        printInvoice,
      }
    },
    created() {
      this.id = this.$route.params.id
      axios
        .get("subscriptions/" + this.id)
        .then((result) => {
            this.subscriptionData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    methods: {
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  @import '~@core/scss/base/pages/app-invoice.scss';
  </style>
  
  <style lang="scss">
  @media print {
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    } // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
              > .col-12 {
                max-width: 50% !important;
              }
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      } // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  #order-table th {
    font-size: 11px;
    padding: 10px 10px;
  }
  </style>
  